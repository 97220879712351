export default defineNuxtRouteMiddleware((to) => {
  const { data } = useAuth()
  const { $updateAbility, $can, $ability } = useNuxtApp()
  $updateAbility(data.value)

  if (!$can('manage', 'User')) {
    throw createError({
      statusCode: 403,
      statusMessage: 'Unauthorized',
    })
  }
})
